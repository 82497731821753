import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Media from '@components/media/Media.tsx';
import tables from '@components/table/static/tables.tsx';
import BalanceT from '@global/models/Balance.ts';

import getFormatPrice from '../../../../functions/getFormatPrice.ts';
import getFormatedDate from '../../../../functions/getFormatedDate.ts';
import setSpacesInText from '../../../../functions/setSpacesInText.ts';

import { RenderColT } from '../../types.ts';

const renderOperations: RenderColT = function (data) {
    const operation = data.item as Omit<BalanceT, 'holder'> & {
        holder: NonNullable<BalanceT['holder']>;
    };
    const name = data.name as keyof (typeof tables)['operations']['cols'];

    if (name === 'date') {
        return (
            <>
                <div className="profileTable__colText">
                    {getFormatedDate({
                        date: new Date(operation.date),
                        type: 'full',
                        isShortYear: true,
                    })}
                </div>
            </>
        );
    }

    if (name === 'holder') {
        const renderContent = () => (
            <>
                <div className={`profileTable__colText _inline`}>{operation.holder?.name}, </div>
                <div className={`profileTable__colText _inline`}>
                    {operation.holder?.description}
                </div>
            </>
        );

        return (
            <>
                <Media current="desktop">
                    <div className="profileTable__holder _ROW">
                        <div className="profileTable__holderAvatar">
                            <Avatar
                                color={operation.holder.color}
                                firstSymb={
                                    operation.holderType === 'juristic'
                                        ? operation.holder.fullName?.[0]
                                        : operation.holder.secondName?.[0]
                                }
                                secondSymb={
                                    operation.holderType === 'juristic'
                                        ? null
                                        : operation.holder.firstName?.[0]
                                }
                            />
                        </div>
                        <div className="profileTable__holderContent">{renderContent()}</div>
                    </div>
                </Media>
                <Media current="mobile">{renderContent()}</Media>
            </>
        );
    }

    if (name === 'amount') {
        return (
            <>
                <div
                    className={`profileTable__colText _NOWRAP ${operation.amount > 0 ? '_successColor' : '_alertColor'}`}
                >
                    {operation.amount > 0 ? '+' : ''}
                    {getFormatPrice(operation.amount)} ₽
                </div>
            </>
        );
    }

    if (name === 'description') {
        return (
            <>
                <div
                    className="profileTable__colText"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(operation.description) }}
                ></div>
            </>
        );
    }

    return null;
};

export default renderOperations;
