import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import addYandexMetrika from '@functions/addYandexMetrika.ts';

import changePage from './functions/changePage.ts';
import getStartUrl from './functions/getStartUrl.ts';
import resize from './functions/handlerSize.ts';

import { StoreT } from './global/types.ts';

import pwaInit from './pwa/init';
import pages from './redux/pages.ts';
import { dispatcher, store } from './redux/redux.ts';
import Root from './views/root/Root.tsx';

const resultPages: StoreT['pages'] = {} as StoreT['pages'];

const path = getStartUrl(window.location.pathname.slice(1));

pages.forEach((page) => {
    resultPages[page.name] = {
        isShow: false,
    };
});

(async () => {
    const { storePages, levels, pagesIds } = await changePage({
        href: path,
        storePages: resultPages,
        start: true,
    });

    if (levels?.[0]) {
        (document.querySelector('body') as HTMLElement).classList.add(`_${levels[0]}`);
    }

    dispatcher({ type: 'pages', data: storePages });
    dispatcher({ type: 'levels', data: levels });
    dispatcher({ type: 'pagesIds', data: pagesIds });
    dispatcher({ type: 'rootInit', data: true });

    addYandexMetrika();
})();

document.addEventListener('scroll', () => {
    resize();
});

window.addEventListener('resize', () => {
    resize();
});

document.oncontextmenu = (e) => {
    e.preventDefault();
};

resize(true);

const loads: {
    event?: boolean;
    fonts?: boolean;
} = {};

const checkLoad = () => {
    if (loads.event && loads.fonts) {
        setTimeout(() => {
            dispatcher({ type: 'windowIsLoad', data: true });
            dispatcher({ type: 'windowIsReady', data: true });

            resize(true);

            document.dispatchEvent(new CustomEvent('windowReady'));
        }, 10);
    }
};

document.fonts.ready.then(() => {
    setTimeout(() => {
        loads.fonts = true;

        dispatcher({ type: 'isLoadFonts', data: true });

        checkLoad();
    }, 10);
});

window.onload = () => {
    setTimeout(() => {
        dispatcher({ type: 'windowIsLoad', data: new Date().getTime() });

        const style = document.querySelector('.initStyle');

        if (style) {
            style.remove();
        }

        loads.event = true;

        checkLoad();
    }, 10);
};

pwaInit();

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    rootElement,
);
